import React, {memo, useState} from "react";
import styled from "styled-components";
import {cutTime} from "../CouriersStatistic"
import PayVariant from "../../share/OrderMapCommonComponents/PayVariant"
import {MoneyIco} from "../../../images/svgs"
import {moneyFormat} from "../CourierInfo"
import {useDispatch} from "react-redux"
import {selectInfoUint} from "../../../reducers/toolkit/orderMapReducer"


const CourierRouteListItem = ({data, counter, toggleOrder, checkedExternalVal}) => {

  const dispatch = useDispatch()
  const {companyInfo, courierRouteData} = data

  const {companyId, timeActual} = courierRouteData
  const {companyAddress, sumPriceResult, sumPriceResultCash, ordId} = companyInfo
  const timeActualCut = timeActual ? cutTime(timeActual.split(' ')[1]) : '--:--'
  const sumPriceResultFix = +(+sumPriceResult).toFixed(0)
  const sumPriceResultCashFix = +(+sumPriceResultCash).toFixed(0)

  return (
    <Wrapper>
      <Counter orderIsDelivered={timeActual}>
        <p>{counter}</p>
      </Counter>
      <OrderInfo>
        <Row>
          <DeliveryTime>{timeActualCut}</DeliveryTime>
          <CompanyId onClick={() => dispatch(selectInfoUint(companyInfo))}>ID {companyId}</CompanyId>
        </Row>
        <Row>
          <Address>
            <p>{companyAddress}</p>
          </Address>
        </Row>
        {/*<Row>*/}
        {/*  <PayVariant sumPriceResultCash={sumPriceResultCashFix} sumPriceResult={sumPriceResultFix}/>*/}
        {/*  <TotalCash>*/}
        {/*    <MoneyIco />*/}
        {/*    <p>{moneyFormat(sumPriceResultFix)}₽</p>*/}
        {/*  </TotalCash>*/}
        {/*</Row>*/}
      </OrderInfo>
      <Check>
        <input type="checkbox" checked={checkedExternalVal} onChange={() => toggleOrder(ordId, companyId, companyInfo)}/>
      </Check>
    </Wrapper>
  )
}

export default memo(CourierRouteListItem);

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #E2E2E2;
  padding: 5px 8px;
`

const Counter = styled.div`
  min-width: 25px;
  min-height: 25px;
  background: ${({orderIsDelivered}) => orderIsDelivered ? '#7AFF8F' : '#000'};
  color: ${({orderIsDelivered}) => orderIsDelivered ? '#000' : '#fff'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  p{
    margin: 0;
    text-align: left;
  }
`
const OrderInfo = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
const Address = styled.div`
  color: rgba(0, 0, 0, 0.51);
  text-align: left;
  
  >p{
    margin: 0;
  }
`
const TotalCash = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 5px;
`
const DeliveryTime = styled.div`

`
const CompanyId = styled.div`
  cursor: pointer;
  color: #3498DB;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  
  :hover{
    text-decoration: underline;
  }
`
const Check = styled.div`

`
